import React from "react"
import Layout from "../components/layout"

const Page404 = () => (
  <Layout>
    <div className="text-center">
      <h1 >
      404 - You must have driven off into the ditch. <br/>
Better get back on the road by going to the Pardeeville Car Show <a href="https://www.pardeevillecarshow.com/">Home page |Page not Found!</a>
      </h1>
       <br/> <br/> <br/> <br/>
    </div>
  </Layout>
)
export default Page404
